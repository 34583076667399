// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.nav-link:focus {  
    outline: none;  
}

.image_picker {
  width: 100%;

  .responsive {
    position: relative;
    margin: 0 6px;
    float: left;
    opacity: 1;

    .thumbnail {
      border: 1px solid #ddd; /* Gray border */
      border-radius: 4px;  /* Rounded border */
      padding: 5px; /* Some padding */
      width: 150px; /* Set a small width */
      opacity: 1;
      margin: 0;

      &:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.7);
      }
    }

    .selected {
      background-color: rgba(0, 140, 186, 1);
    }
    
    &:hover {
      cursor: pointer;
    }

    .checked {
      display: none;
    }
  }

  .selected {
    opacity: 0.7;

    .checked {
      display: inline-block;
      transition: .5s ease;
      position: absolute;
      bottom: 0;
      right: 0;

      .icon {
        background: url("./app/utils/ImagePicker/ImagePicker_check.png");
        background-size: cover;
        height: 75px;
        width: 75px;
      }
    }
  }

  .clear {
    clear: both;
  }
}


.rmdp-container {
  box-sizing: content-box !important;
  .rmdp-time-picker{
    input{
      box-sizing: content-box !important;
      &::after,&::before{
        box-sizing: content-box !important;
      }
    }
  }
}


.custom-switch.custom-switch-lg {
  padding-bottom: 1rem;
  padding-left: 2.25rem;

  & .custom-control-label {
      padding-left: .75rem;
      padding-top: 0.15rem;

      &::before {
          cursor: pointer;
          border-radius: 1rem;
          height: 1.5rem;
          width: 2.5rem;
      }

      &::after {
          cursor: pointer;
          border-radius: .65rem;
          height: calc(1.5rem - 4px);
          width: calc(1.5rem - 4px);
      }
  }
  
  .custom-control-input:checked ~ .custom-control-label::after{
      transform: translateX(1rem);
  }
}

.form-control-custom-disabled{
  &:disabled{
    background-color: #ffff;
  }
}


.fc-header-toolbar{
  @media screen and(max-width:720px){
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .fc-toolbar-chunk{
    @media screen and(max-width:720px){
      margin-bottom: 5px;
      margin-top: 5px;
    } 
  }
}